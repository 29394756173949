<div class="card bg-light mx-2 mx-sm-3 mx-md-4 mx-lg-5 my-3 mt-md-4">
  <div class="card-body">
    <p class="card-text">
      Location: {{location}}
    </p>
    <p class="card-text">
      Service Worker status: {{serviceWorkerStatus}}
    </p>
  </div>

  <div class="card-footer text-right">
    <button class="btn btn-primary" onClick="document.location.reload(true)">Reload page</button>
  </div>
</div>

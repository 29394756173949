<ng-template #navigation>
  <a class="ml-2 ml-md-3 mr-2" [routerLink]="['/']"
     [attr.aria-label]="'NAV.START.DESCRIPTION' | translate">Start</a>
  <span role="separator" aria-hidden="true">/</span>
  <a *ngIf="campusName$ | async as name"
     class="ml-2 text-truncate" [routerLink]="['/campus', campus]"
     [attr.aria-label]="'NAV.CAMPUS.DESCRIPTION' | translate:{campus: name}">{{name}}</a>
</ng-template>

<app-tabbed-container [navigation]="navigation" [tabInfo]="displayTabInfo">

  <!-- Body -->
  <ng-container *ngIf="menuInfo$ | async as menuInfo; else loadingMenu">
    <ng-container *ngIf="menuInfo.isLoaded; else errorLoading">
      <div *ngIf="getClosedDisplay(menuInfo.response) as closedText; else notClosed"
           class="card-body highlightable-inside"
           role="status" tabindex="-1">
        {{closedText}}
      </div>

      <ng-template #notClosed>
        <div class="list-group list-group-flush d-block" *ngIf="menuInfo.response.menu.length > 0; else noMenuItems"
             [attr.aria-label]="'LIST.DESCRIPTION' | translate:{length:menuInfo.response.menu.length}">
          <div *ngIf="isMenuProvisional"
               class="list-group-item d-flex p-0 align-items-center highlightable-inside"
               tabindex="-1" role="text">
            <img class="ml-3 mr-1 my-2 flex-shrink-0" style="width: 2em; height: 2em;"
                 src="/assets/twemoji/indicator_warning.svg"
                 aria-hidden="true" role="none"/>
            <span class="item-name m-2 ml-0 mr-1">
              {{'MENU.PROVISIONAL' | translate}}
            </span>
          </div>
          <div *ngFor="let item of menuInfo.response.menu; let i = index; let len = count"
               class="list-group-item d-flex p-0 align-items-center highlightable-inside"
               tabindex="-1" role="text">
            <span [attr.aria-label]="'LIST.ITEM.DESCRIPTION' | translate:{index:i + 1, length:len}"></span>
            <img class="ml-3 mr-1 my-2 flex-shrink-0" style="width: 2em; height: 2em;"
                 [src]="getIconURL(item)"
                 [alt]="getIconDescription(item) | translate" [title]="getIconDescription(item) | translate"/>
            <span class="item-name m-2 ml-0 mr-1">
              {{getTranslation(item)}}
            </span>
            <span aria-label="."></span>
            <span class="item-price m-2 ml-auto" [attr.aria-label]="getPriceDescription(item) | translate:item">
              {{getPriceDisplay(item)}}
            </span>
          </div>
        </div>
      </ng-template>


      <ng-template #noMenuItems>
        <div class="card-body highlightable-inside"
             role="status" tabindex="-1">
          {{'MENU.EMPTY' | translate}}
        </div>
      </ng-template>
    </ng-container>

    <ng-template #errorLoading>
      <ng-container *ngIf="menuInfo.isErrored; else loadingMenu">
        <div class="card-body highlightable-inside"
             role="status" tabindex="-1">
          {{'MENU.ERROR' | translate}}
        </div>
      </ng-container>
    </ng-template>
  </ng-container>

  <!-- Fallback body -->
  <ng-template #loadingMenu>
    <div class="card-body">{{'MENU.LOADING' | translate}}</div>
  </ng-template>

</app-tabbed-container>

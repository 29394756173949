<nav class="navbar navbar-dark bg-primary">
  <a class="navbar-brand" [routerLink]="['/']">Komidabot</a>
  <!--  <button class="btn btn-light" onClick="document.location.reload(true)">Reload page</button>-->
  <ng-container *ngIf="!isOnSettings(); else settingsPage">
    <a class="mr-3 ml-auto d-block text-light text-center"
       style="font-size: 1.5rem; width: 2rem; height: 2rem; line-height: 2rem;"
       [routerLink]="['/settings']"
       [attr.aria-label]="'NAV.COG.DESCRIPTION' | translate">
      <fa-icon [icon]="faCog"></fa-icon>
    </a>
    <div *ngIf="language$ | async as language" class="btn-group btn-group-toggle" role="group">
      <label class="btn btn-light" [class.active]="language === 'nl'"
             (click)="setLanguage('nl')"
             tabindex="0" lang="nl" aria-label="Nederlands"
             role="radio" [attr.aria-checked]="language === 'nl'">
        NL
      </label>
      <label class="btn btn-light" [class.active]="language === 'en'"
             (click)="setLanguage('en')"
             tabindex="0" lang="en" aria-label="English"
             role="radio" [attr.aria-checked]="language === 'en'">
        EN
      </label>
    </div>
  </ng-container>
  <ng-template #settingsPage>
    <a class="mr-3 ml-auto d-block text-light text-center"
       style="font-size: 1.5rem; width: 2rem; height: 2rem; line-height: 2rem;"
       [routerLink]="['/admin']"
       [attr.aria-label]="'NAV.KEY.DESCRIPTION' | translate">
      <fa-icon [icon]="faKey"></fa-icon>
    </a>
    <a class="btn btn-light"
       [routerLink]="['/']">
      {{'SETTINGS.GO_BACK' | translate}}
    </a>
  </ng-template>
</nav>
<a *ngIf="updateAvailable"
   class="d-block p-2 bg-warning text-center"
   style="cursor: pointer;"
   (click)="refreshPage()"
   role="status"
   [attr.aria-label]="'APP.UPDATE_AVAILABLE.DESCRIPTION' | translate"
   aria-live="polite"
>{{'APP.UPDATE_AVAILABLE' | translate}}</a>

<a *ngIf="botRedirect"
   class="d-block p-2 bg-warning text-center"
   style="cursor: pointer; text-decoration: none;"
   href="https://komidabot.xyz/"
   role="status"
   aria-live="polite"
>{{'APP.DOMAIN_CHANGE' | translate}}</a>

<div *ngIf="(language$ | async) === 'fr'"
     class="alert alert-info mx-2 mx-sm-3 mx-md-4 mx-lg-5 my-1 mt-md-4">
  Let op. Frans is momenteel geen ondersteunde taal.
</div>

<!--
<a class="d-block p-2 bg-warning text-center"
   style="cursor: pointer; text-decoration: none;"
   [href]="'APP.COVID19_INFO_LINK' | translate"
>{{'APP.COVID19_UNAVAILABLE' | translate}}</a>
-->

<!--<div>{{deviceInfo | json}}</div>-->
<router-outlet (activate)="onRouterActivate($event)"></router-outlet>
<app-splash-screen *ngIf="displaySplash" (delete)="splashComplete()"></app-splash-screen>

<app-cookie-banner></app-cookie-banner>

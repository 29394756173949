<div class="card mx-2 mx-sm-3 mx-md-4 mx-lg-5 my-3 mt-md-4" style="margin-bottom: 0 !important;"
     role="main">
  <!-- Header -->
  <div class="card-header d-flex align-items-center pr-2">
    <span class="mr-auto" role="heading" aria-level="1">{{'CAMPUS_LIST.HEADER' | translate}}</span>
    <span *ngIf="displayTodayButton" role="none" aria-hidden="true">{{'CAMPUS_LIST.TODAY' | translate}}</span>
  </div>

  <!-- Body -->
  <ng-container *ngIf="campusInfo$ | async as campusInfo; else loadingCampuses">
    <ng-container *ngIf="campusInfo.isLoaded; else errorLoading">
      <div class="list-group list-group-flush">
        <div *ngFor="let campusInfo of campusInfo.response; let even = even; let odd = odd"
             class="list-group-item d-flex p-0 align-items-center">
          <a [routerLink]="['/campus', campusInfo.campus.short_name]"
             class="campus-item py-2 px-3 flex-grow-1 highlightable-inside"
             [class.closed]="isCampusClosed(campusInfo)">
            <ng-container *ngIf="getCampusSubscript(campusInfo) as subscript; else noSubscript">
              <span role="text">
                <span class="card-title d-block m-0">
                  <img *ngIf="isCampusClosed(campusInfo)"
                       src="/assets/twemoji/indicator_closed.svg"
                       class="emoji"
                       aria-hidden="true" role="none"/>
                  <span *ngIf="isCampusClosed(campusInfo)"
                        [attr.aria-label]="'CAMPUS.CLOSED.DESCRIPTION' | translate"></span>

                  {{campusInfo.campus.name}}
                </span>
                <span aria-label="."></span>
                <span class="card-subtitle d-block m-0 campus-subtitle">
                {{subscript}}
                </span>
              </span>
            </ng-container>
            <ng-template #noSubscript>
              <span class="card-title d-block m-2" role="text">
                <img *ngIf="isCampusClosed(campusInfo)"
                     src="/assets/twemoji/indicator_closed.svg"
                     class="emoji"
                     role="none"/>

                {{campusInfo.campus.name}}
              </span>
            </ng-template>
          </a>
          <!--          <a *ngIf="!isCampusClosed(campusInfo)"-->
          <!--             class="border-left align-self-stretch align-items-center d-flex"-->
          <!--             style="line-height: 100%;"-->
          <!--             [routerLink]="['/campus', campusInfo.campus.short_name, 'd', dayForUrl(today)]">-->
          <!--            <span class="py-2 px-3">{{'CAMPUS_LIST.TODAY' | translate}}</span>-->
          <!--          </a>-->
          <a *ngIf="displayTodayButton"
             class="border-left align-self-stretch align-items-center d-flex highlightable-inside"
             style="line-height: 100%;"
             [routerLink]="['/campus', campusInfo.campus.short_name, 'd', dayForUrl(today)]"
             [attr.aria-label]="'CAMPUS_LIST.GO.DESCRIPTION' | translate:translateInformation(campusInfo)">
            <img class="mx-3 my-2 flex-shrink-0" style="width: 2em; height: 2em;"
                 src="/assets/twemoji/indicator_go-to-today.svg"
                 role="none"/>
          </a>
        </div>
      </div>
    </ng-container>

    <ng-template #errorLoading>
      <ng-container *ngIf="campusInfo.isErrored; else loadingCampuses">
        <div class="card-body highlightable-inside"
             role="status" tabindex="-1">
          {{'CAMPUS_LIST.ERROR' | translate}}
        </div>
      </ng-container>
    </ng-template>
  </ng-container>

  <!-- Fallback body -->
  <ng-template #loadingCampuses>
    <div class="card-body">{{'CAMPUS_LIST.LOADING' | translate}}</div>
  </ng-template>
</div>

<!--<div class="my-4 mx-5 d-flex justify-content-end">-->
<!--  <a class="small" [routerLink]="['/about']">{{'ABOUT' | translate}}</a>-->
<!--</div>-->

<div class="card mx-2 mx-sm-3 mx-md-4 mx-lg-5 my-3 mt-md-4" style="overflow: hidden;"
     role="main">
  <!-- Header -->
  <div class="card-header" role="heading" aria-level="1">
    {{'LOGIN.TITLE' | translate}}
  </div>

  <div class="card-body">
    {{'LOGIN.REDIRECTING' | translate}}
  </div>
</div>

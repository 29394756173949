<div class="card mx-2 mx-sm-3 mx-md-4 mx-lg-5 my-3 mt-md-4" style="overflow: hidden;">
  <div class="list-group list-group-flush">
    <span *ngFor="let image of images"
          class="list-group-item d-flex p-0 align-items-center">
      <img class="ml-3 mr-1 my-2 flex-shrink-0" style="width: 2em; height: 2em;"
           [src]="image.url"/>
      <span class="item-name m-2 ml-0 mr-1">
        {{image.description}}
      </span>
    </span>
  </div>
</div>

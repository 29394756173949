<div *ngIf="display$ | async as display"
     class="container-fluid">
  <div class="row overflow-hidden">
    <div *ngFor="let campus of display.campuses; let i = index"
         class="col overflow-hidden">
      <div style="margin: -1rem -3.5rem;">
        TODO: Fix this
        <!--
        <app-campus-day-menu [campus]="campus"
                             [day]="display.menu_day"></app-campus-day-menu>
        -->
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="subscriptionState$ | async as state">
  <ng-container *ngIf="state.subscribed; else unsubscribed">
    <button class="btn btn-primary" (click)="unsubscribe()">
      Unsubscribe
    </button>
  </ng-container>
  <ng-template #unsubscribed>
    <button [disabled]="!state.subscribable" class="btn btn-primary"
            (click)="subscribe()">
      Subscribe
    </button>
  </ng-template>
</ng-container>
